import { zodResolver } from "@hookform/resolvers/zod";
import { useParams, useSearch } from "@tanstack/react-router";
import { useContext, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { useSuspensePayment } from "../../api/use-payment";
import { ModalContext } from "../../providers/modal";
import { StartBankConnection } from "../modals/open-banking-connection";
import { Button } from "../ui/button";
import { CustomerInformation } from "./customer-information";
import { ManagePaymentMethods } from "./manage-payment-methods";

const formSchema = z.intersection(
	z.object({
		email: z.string().optional(),
	}),
	z.record(z.string(), z.union([z.string(), z.null()])),
);

export type FormValues = z.infer<typeof formSchema>;

export function PaymentWrapper() {
	const ctx = useContext(ModalContext);
	const { paymentId } = useParams({ from: "/pay/$paymentId" });
	const { locale, method, ...search } = useSearch({ from: "/pay/$paymentId" });
	const { payment } = useSuspensePayment(paymentId);

	const methods = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: {
			email: payment.customerEmail ?? search.email ?? "",
		},
	});

	async function onSubmit({ email }: FormValues) {
		if (
			payment.isEmailRequired &&
			!z.string().email().safeParse(email).success
		) {
			methods.setError("email", {
				message: "Email required",
			});

			return;
		}

		if (method === "bank") {
			const bankId = methods.getValues("bankId");

			if (!bankId) {
				methods.setError("bankId", {
					message: "Bank required",
				});

				return;
			}

			ctx?.open(<StartBankConnection email={email} bankId={bankId} />);
		}
	}

	const buttonLabel = useMemo(() => {
		// if (method === "paypal") {
		//   return "Pay with PayPal";
		// }

		if (method === "bank") {
			return "Pay by bank";
		}

		return "Pay";
	}, [method]);

	return (
		<div className="w-full h-[calc(100vh_-_13rem)] md:h-full flex justify-center xl:justify-start items-center">
			<div className="w-full h-full md:h-[75vh] max-w-md flex flex-col space-y-6 px-4 py-8 md:py-4 xl:ml-4 2xl:ml-16">
				<FormProvider {...methods}>
					<form
						onSubmit={methods.handleSubmit(onSubmit)}
						className="flex flex-col space-y-6"
					>
						{payment.isEmailRequired ? (
							<div className="flex flex-col space-y-4">
								<h2 className="font-medium text-neutral-700">
									Customer information
								</h2>
								<CustomerInformation email={payment.customerEmail} />
							</div>
						) : null}
						<div className="flex flex-col space-y-4">
							<h3 className="font-medium text-neutral-700">Payment method</h3>
							{/* <SelectPaymentMethod /> */}
							<ManagePaymentMethods />
						</div>
						<Button
							isLoading={methods.formState.isSubmitting}
							intent="solid"
							size="big"
							type="submit"
							// className={cn(!isFormValid() && "!text-neutral-400")}
						>
							{buttonLabel}
						</Button>
						<div className="h-12" />
					</form>
				</FormProvider>
			</div>
		</div>
	);
}
