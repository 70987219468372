import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet, createRootRoute } from "@tanstack/react-router";
import { NuqsAdapter } from "nuqs/adapters/react";
import { ErrorMessage } from "../components/common/error-message";
import { Loading } from "../components/common/loading";
import { ModalProvider } from "../providers/modal";

export const queryClient = new QueryClient();

export const rootRoute = createRootRoute({
	component: RootLayout,
	errorComponent: () => <ErrorMessage />,
	// notFoundComponent: () => {
	// 	window.location.replace("https://inflowpay.xyz");

	// 	return <Loading className="w-screen h-screen" />;
	// },
	pendingComponent: () => <Loading className="w-screen h-screen" />,
});

function RootLayout() {
	return (
		<QueryClientProvider client={queryClient}>
			<NuqsAdapter>
				<div className="relative text-sm md:text-base">
					<ModalProvider>
						<Outlet />
					</ModalProvider>
				</div>
			</NuqsAdapter>
		</QueryClientProvider>
	);
}
