import { useBanks, useSuspenseBanks } from "./use-banks";

type Data = {
	paymentId: string;
	bankId: string;
	groupId?: string | null;
	locale?: string | null;
};

export function useBank(props: Data) {
	const { banks, ...query } = useBanks(props);

	return {
		bank: banks?.banks.find((bank) => bank.id === props.bankId),
		...query,
	};
}

export function useSuspenseBank(props: Data) {
	const { banks, ...query } = useSuspenseBanks(props);

	return {
		bank: banks?.banks.find((bank) => bank.id === props.bankId),
		...query,
	};
}
