import { useQueryClient } from "@tanstack/react-query";
import { useParams, useSearch } from "@tanstack/react-router";
import { useContext, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import QRCode from "react-qr-code";
import { useBank } from "../../api/use-bank";
import { banksOptions, useBanks } from "../../api/use-banks";
import { useSuspensePayment } from "../../api/use-payment";
import { redirectUrlOptions, useRedirectUrl } from "../../api/use-redirect-url";
import { BankIcon } from "../../assets/bank";
import { LinkIcon } from "../../assets/link";
import { LockIcon } from "../../assets/lock";
import { ShieldIcon } from "../../assets/shield";
import { XIcon } from "../../assets/x";
import { cn } from "../../lib/cn";
import { ModalContext } from "../../providers/modal";
import { Loading } from "../common/loading";
import { Button } from "../ui/button";

type StartBankConnectionProps = {
	email?: string;
	bankId: string;
	groupId?: string;
};

export function StartBankConnection({
	email,
	bankId,
}: StartBankConnectionProps) {
	const ctx = useContext(ModalContext);
	const { paymentId } = useParams({ from: "/pay/$paymentId" });
	const { payment } = useSuspensePayment(paymentId);
	const { locale } = useSearch({ from: "/pay/$paymentId" });
	const { bank } = useBank({
		paymentId,
		bankId,
		locale,
	});
	const queryClient = useQueryClient();

	// prefetching logic
	useEffect(() => {
		if (!bank) {
			return;
		}

		if (bank.groupId) {
			queryClient.prefetchQuery(
				banksOptions({
					paymentId,
					locale,
					groupId: bank.groupId ?? "",
				}),
			);
		} else {
			queryClient.prefetchQuery(
				redirectUrlOptions({
					paymentId,
					email,
					method: "bank",
					bankId,
				}),
			);
		}
	}, [queryClient, bank, paymentId, locale, email, bankId]);

	if (!bank) {
		return (
			<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between md:rounded-3xl bg-white">
				<Loading />
			</div>
		);
	}

	return (
		<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between space-y-10 p-3 md:rounded-3xl bg-white">
			<div className="h-full w-full flex flex-col items-center space-y-2">
				<div className="w-full flex justify-end">
					<button
						type="button"
						onClick={() => ctx?.close()}
						className="mt-0.5 h-6 w-6 -left-10 mr-2 flex justify-center items-center rounded-full cursor-pointer duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
					>
						<XIcon className="w-5 h-5 text-neutral-700" />
					</button>
				</div>
				{bank.logo ? (
					<img
						src={bank.logo}
						alt={`${bank.name} logo`}
						className="shrink-0 h-20 w-20"
					/>
				) : (
					<div className="shrink-0 w-20 h-20 flex justify-center items-center bg-neutral-100 rounded-full">
						<BankIcon className="w-8 h-8 text-neutral-600" />
					</div>
				)}
				<span className="text-2xl font-semibold text-neutral-700 text-center">
					Log in with {bank.name}
				</span>
				<div className="h-10" />
				<div className="flex flex-col space-y-2">
					<div className="flex items-start space-x-3">
						<LinkIcon className="shrink-0 mt-0.5 w-5 h-5 text-neutral-600" />
						<span className="text-base font-medium text-neutral-600">
							{payment.merchant} uses Inflow to connect to your acounts.
						</span>
					</div>
					<div className="flex items-start space-x-3">
						<ShieldIcon className="shrink-0 mt-0.5 w-5 h-5 text-neutral-600" />
						<span className="text-base font-medium text-neutral-600">
							Your data is 100% encrypted
						</span>
					</div>
				</div>
			</div>
			<div className="w-full flex flex-col space-y-2 items-center">
				<span className="text-xs text-neutral-400 font-medium flex space-x-2 items-center">
					<LockIcon className="w-3 h-3 fill-green-400" />
					<span>Secured by Inflow</span>
				</span>
				<Button
					intent="solid"
					size="big"
					type="button"
					onClick={() => {
						if (bank.groupId) {
							ctx?.open(
								<SelectBankFromGroup
									groupId={bank.groupId}
									bankId={bank.id}
									email={email}
								/>,
							);
						} else {
							ctx?.open(<BankConnection email={email} bankId={bank.id} />);
						}
					}}
					className="w-full"
				>
					Continue
				</Button>
			</div>
		</div>
	);
}

type SelectBankFromGroupProps = {
	bankId: string;
	groupId: string;
	email?: string;
};

export function SelectBankFromGroup({
	bankId,
	groupId,
	email,
}: SelectBankFromGroupProps) {
	const ctx = useContext(ModalContext);
	const [selectedBank, setSelectedBank] = useState<string | null>(null);
	const { paymentId } = useParams({ from: "/pay/$paymentId" });
	const { locale } = useSearch({ from: "/pay/$paymentId" });
	const { bank } = useBank({
		paymentId,
		locale,
		bankId,
	});
	const { banks, isLoading } = useBanks({
		paymentId,
		locale,
		groupId,
	});

	if (!bank) {
		return (
			<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between md:rounded-3xl bg-white">
				<Loading />
			</div>
		);
	}

	return (
		<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between space-y-10 p-3 md:rounded-3xl bg-white">
			<div className="h-full w-full flex flex-col items-center space-y-2">
				<div className="w-full flex justify-end">
					<button
						type="button"
						onClick={() => ctx?.close()}
						className="mt-0.5 h-6 w-6 -left-10 mr-2 flex justify-center items-center rounded-full cursor-pointer duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
					>
						<XIcon className="w-5 h-5 text-neutral-700" />
					</button>
				</div>
				{bank.logo ? (
					<img
						src={bank?.logo}
						alt={`${bank?.name} logo`}
						className="shrink-0 h-20 w-20"
					/>
				) : (
					<div className="shrink-0 w-20 h-20 flex justify-center items-center bg-neutral-100 rounded-full">
						<BankIcon className="w-8 h-8 text-neutral-600" />
					</div>
				)}
				<span className="text-2xl font-semibold text-neutral-700 text-center">
					Select your branch
				</span>
				<div className="h-10" />
				<div className="w-full h-full py-2 flex flex-col items-center space-y-4 overflow-y-auto">
					{banks?.banks.map((branch, i) => (
						<button
							key={`${branch.id}-${i}`}
							type="button"
							onClick={() => {
								setSelectedBank(branch.id);
							}}
							className={cn(
								"w-full py-2 px-4 text-sm text-left border rounded-xl hover:border-neutral-400 duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40 shadow-sm",
								selectedBank === branch.id
									? "border-neutral-500 bg-neutral-100"
									: "border-neutral-300",
							)}
						>
							{branch.name}
						</button>
					))}
					{banks?.banks.length === 0 && !isLoading && (
						<div className="w-full h-full py-2 px-4 flex justify-center items-center border border-dashed border-neutral-300 rounded-xl">
							<span className="text-neutral-500 text-sm font-semibold">
								No branches found
							</span>
						</div>
					)}
					{isLoading &&
						[...Array(6)].fill(null).map((_, i) => (
							<div
								// biome-ignore lint/suspicious/noArrayIndexKey: what else
								key={i}
								className="w-full h-full py-2 px-4 flex border border-neutral-300 rounded-xl animate-pulse"
							>
								<div className="w-48 h-5 bg-neutral-100 rounded-full" />
							</div>
						))}
				</div>
				<Button
					intent="solid"
					size="big"
					type="button"
					disabled={!selectedBank}
					onClick={() => {
						if (selectedBank) {
							ctx?.open(
								<BankConnection
									email={email}
									bankId={selectedBank}
									groupId={bank.groupId ?? undefined}
								/>,
							);
						}
					}}
					className="w-full"
				>
					Continue
				</Button>
			</div>
		</div>
	);
}

type BankConnectionProps = {
	email?: string;
	bankId: string;
	groupId?: string;
};

export function BankConnection({
	email,
	bankId,
	groupId,
}: BankConnectionProps) {
	const ctx = useContext(ModalContext);
	const { paymentId } = useParams({ from: "/pay/$paymentId" });
	const { locale } = useSearch({ from: "/pay/$paymentId" });
	const { bank } = useBank({
		paymentId,
		bankId,
		groupId,
		locale,
	});
	const { redirectUrl } = useRedirectUrl({
		paymentId,
		email,
		method: "bank",
		bankId,
	});

	useEffect(() => {
		if (!isDesktop && redirectUrl?.linkUrl) {
			window.location.replace(redirectUrl.linkUrl);
		}
	}, [redirectUrl?.linkUrl]);

	if (!bank) {
		return (
			<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between md:rounded-3xl bg-white">
				<Loading />
			</div>
		);
	}

	return (
		<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between space-y-10 p-3 md:rounded-3xl bg-white">
			<div className="h-full w-full flex flex-col items-center space-y-2">
				<div className="w-full flex justify-end">
					<button
						type="button"
						onClick={() => ctx?.close()}
						className="mt-0.5 h-6 w-6 -left-10 mr-2 flex justify-center items-center rounded-full cursor-pointer duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
					>
						<XIcon className="w-5 h-5 text-neutral-700" />
					</button>
				</div>
				{bank.logo ? (
					<img
						src={bank?.logo}
						alt={`${bank?.name} logo`}
						className="shrink-0 h-20 w-20"
					/>
				) : (
					<div className="shrink-0 w-20 h-20 flex justify-center items-center bg-neutral-100 rounded-full">
						<BankIcon className="w-8 h-8 text-neutral-600" />
					</div>
				)}
				<span className="text-2xl font-semibold text-neutral-700 text-center">
					Log in with {bank?.name}
				</span>
				<div className="h-10" />
				<div className="h-full flex flex-col items-center justify-between">
					<div className="flex flex-col items-center space-y-8">
						<span className="text-base text-center font-medium text-neutral-600">
							Scan this QR code with your phone camera to complete the bank
							login in the app
						</span>
						{redirectUrl ? (
							<QRCode value={redirectUrl.linkUrl} className="w-40 h-40" />
						) : (
							<div className="w-40 h-40 flex flex-col space-y-2 items-center justify-center">
								<Loading className="w-min h-min" />
								<span className="text-neutral-500 text-sm font-semibold">
									Generating QR code...
								</span>
							</div>
						)}
					</div>
					{redirectUrl ? (
						<span className="text-neutral-500 text-sm font-semibold">
							Want to continue on your computer?{" "}
							<a
								href={redirectUrl.linkUrl}
								aria-disabled={!!redirectUrl}
								target="_blank"
								rel="noreferrer"
								className="underline decoration-dotted"
							>
								Click here
							</a>
						</span>
					) : null}
				</div>
			</div>
		</div>
	);
}
