import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import type { QueryOptions } from "./types";

type Options = QueryOptions<Awaited<ReturnType<typeof fn>>>;

type BankData = {
	method: "bank";
	bankId: string;
};

type Data = {
	paymentId: string;
	email?: string;
} & BankData;

async function fn({ paymentId, email, method, ...data }: Data) {
	if (method === "bank") {
		const url = new URL(
			`/checkout/submit/${paymentId}`,
			import.meta.env.VITE_API_URL,
		);

		return fetcher(
			url.toString(),
			z.object({
				linkUrl: z.string(),
			}),
			{
				method: "POST",
				body: JSON.stringify({
					email: email,
					bankId: data.bankId,
				}),
			},
		);
	}
}

export function redirectUrlOptions(props: Data, options: Options = {}) {
	return queryOptions({
		queryKey: [
			"redirect-url",
			props.paymentId,
			props.method,
			props.email,
			props.bankId,
		],
		queryFn: () => fn(props),
		// always fetch new url
		staleTime: 0,
		gcTime: 0,
		...options,
	});
}

export function useRedirectUrl(props: Data, options: Options = {}) {
	const { data, ...query } = useQuery(redirectUrlOptions(props, options));

	return {
		redirectUrl: data,
		...query,
	};
}

export function useSuspenseRedirectUrl(props: Data, options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(
		redirectUrlOptions(props, options),
	);

	return {
		redirectUrl: data,
		...query,
	};
}
